import { useEffect, useReducer } from "react";
import { startOfYear } from "utils/date";
import { ReactSubscriber } from "utils/ReactSubscriber";

interface StorageState {
  reportDate: Date;
}

class GlobalReportDate extends ReactSubscriber<StorageState> {
  constructor() {
    super({
      reportDate: new Date(),
    });
  }

  setReportDate(newDate: Date) {
    this.state.reportDate = newDate;
    this.updateState();
  }
}

const globalReportDate = new GlobalReportDate();

export const useGlobalReportDate = () => {
  const [, forceRender] = useReducer((previous) => previous + 1, 0);

  useEffect(() => {
    const index = globalReportDate.subscribe(forceRender);
    return () => globalReportDate.unsubscribe(index);
  }, []);

  return {
    ...globalReportDate.State,
    setReportDate: (newDate: Date) => {
      globalReportDate.setReportDate(newDate);
    },
  };
};
