import { ElementType as ReactElementType, Fragment, ReactNode, useEffect} from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  Process,
  useGetContactProcesses,
} from "api/flowable/useGetContactProcesses";
import { Representee, useGetContactInfo } from "api/initial/useGetContactInfo";
import { ReactComponent as CheckIcon } from "assets/check.svg";
import { ReactComponent as DepositIcon } from "assets/deposit.svg";
import { ReactComponent as ProcessIcon } from "assets/external-link.svg";
import { ReactComponent as LogoutIcon } from "assets/logout.svg";
import { ReactComponent as SearchIcon } from "assets/search.svg";
import { ReactComponent as UserIcon } from "assets/user-circle.svg";
import { ReactComponent as MenuIcon } from "assets/view-list.svg";
import { ReactComponent as WithdrawalIcon } from "assets/withdrawal.svg";
import classNames from "classnames";
import { Input } from "components/Input/Input";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import i18n from "i18next";
import { useGetContractIdData, SelectedContact } from "providers/ContractIdProvider";
import { useKeycloak } from "providers/KeycloakProvider";
import { useNavigate, To, NavigateOptions } from "react-router";
import Select from "react-select";
import { keycloakService } from "services/keycloakService";
import { useCanDeposit, useCanWithdraw } from "services/permissions/money";
import { initials } from "utils/initials";
import { useModal } from "../Modal/useModal";
import { DepositModalContent } from "../MoneyModals/DepositModalContent/DepositModalContent";
import { WithdrawModalContent } from "../MoneyModals/WithdrawModalContent/WithdrawModalContent";



interface MenuActions {
  logout: () => void;
  deposit: () => void;
  withdraw: () => void;
  process: (to: To, options?: NavigateOptions) => void;
  setSelectedContact: (contact: SelectedContact) => void;
}


const getMenuItems = (
  menuActions: MenuActions,
  hasLinkedContact: boolean,
  canDeposit: boolean,
  canWithdraw: boolean,
  processes: Process[],
  representees: Representee[],
  contactData: SelectedContact,
  selectedContactId: string | number,
) => {
  if (!hasLinkedContact) {
    return [
      {
        label: i18n.t("userMenu.logout"),
        action: menuActions.logout,
        Icon: LogoutIcon,
      },
    ];
  }

  /* Removed from 
    ...(canDeposit
      ? [
          {
            label: i18n.t("userMenu.deposit"),
            action: menuActions.deposit,
            Icon: DepositIcon,
          },
        ]
      : []),
    "separator",
    ...(canWithdraw
      ? [
          {
            label: i18n.t("userMenu.withdraw"),
            action: menuActions.withdraw,
            Icon: WithdrawalIcon,
          },
        ]
      : []),


    "separator",
    ...processes.map((process) => ({
      label: process.name,
      action: () =>
        menuActions.process(`/form/${process.key}`, {
          state: { header: process.name },
        }),
      Icon: ProcessIcon,
    })),

  */   

  return [
    {
      label: contactData?.userName,
      action: () => {menuActions.setSelectedContact(contactData)},
      Icon: UserIcon,
      selected: contactData?.id === selectedContactId,
    },
    "separator",
    ...(Array.isArray(representees)
      ? representees.map((representee, index) => ({
          label: representee?.name,
          action: () => {menuActions.setSelectedContact({
            id: representee.id, contactId: representee.contactId, userName: representee.name, initials: initials(representee.name), portfolios: representee?.portfolios?.filter(portfolio => portfolio.status === "A") || [],
          })}, 
          Icon: UserIcon,
          selected: representee?.id === selectedContactId,
        }))
      : []),
    "separator",
    "separator",
    {
      label: i18n.t("userMenu.logout"),
      action: menuActions.logout,
      Icon: LogoutIcon,
    },
  ];
};

const getMenuItemsSelect = (
  menuActions: MenuActions,
  hasLinkedContact: boolean,
  canDeposit: boolean,
  canWithdraw: boolean,
  processes: Process[],
  representees: Representee[],
  contactData: SelectedContact,
  selectedContactId: string | number,
) => {
  if (!hasLinkedContact) {
    return [
      {
        label: i18n.t("userMenu.logout"),
        action: menuActions.logout,
        Icon: LogoutIcon,
      },
    ];
  }
    
    const handleChange = (representee: Representee | null) => {
      if (representee) {
      const mnBtn = document.getElementById("headlessuiMenyClicker")
      if (mnBtn) {
        mnBtn.click()
      }

      menuActions.setSelectedContact({
        id: representee.id, contactId: representee.contactId, userName: representee.name, initials: initials(representee.name), portfolios: representee?.portfolios?.filter(portfolio => portfolio.status === "A") || [],
      })

    }
    };

    
  
    const getOptionLabel = (selected: Representee | null) => {
      if (selected) {
      return selected.name
      } else {
        return ""
      }
    };
  
    

  return [
    {
      label: contactData?.userName,
      action: () => {menuActions.setSelectedContact(contactData)},
      Icon: UserIcon,
      selected: contactData?.id === selectedContactId,
    },
    "separator",
    {
      label:           <div>
      <Select
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 0,
            boxShadow: "none",
            borderColor: state.isFocused ? 'grey' : 'red',
          }),
        }}
        className="w-96 min-w-full md:min-w-56"
        options={representees}
        getOptionLabel={getOptionLabel}
        onChange={handleChange}
        ></Select>
          </div>,
      Icon: SearchIcon,
    },
    "separator",
    {
      label: i18n.t("userMenu.logout"),
      action: menuActions.logout,
      Icon: LogoutIcon,
    },
  ];
};


export const UserMenu = () => {
  const { selectedContactId, setSelectedContactId, setSelectedContact, setSelectedPortfolios } = useGetContractIdData();
  const { t } = useModifiedTranslation();
  const { linkedContact } = useKeycloak();
  const navigate = useNavigate();
  const { data: processes = [] } = useGetContactProcesses();
  const canDeposit = useCanDeposit();
  const canWithdraw = useCanWithdraw();
  const { data: contactData } = useGetContactInfo();
  const {
    Modal,
    onOpen: onDepositModalOpen,
    modalProps: depositModalProps,
    contentProps: depositModalContentProps,
  } = useModal();

  const {
    onOpen: onWithdrawModalOpen,
    modalProps: withdrawModalProps,
    contentProps: withdrawModalContentProps,
  } = useModal();

  function Redirecter() {
    window.location.replace('https://invento.fasolutions.com/sso/logout');
  }

  const menuActions = {
    logout: () => keycloakService.onAuthLogout(),
    //logout: () => Redirecter(),
    deposit: () => onDepositModalOpen(),
    withdraw: () => onWithdrawModalOpen(),
    process: (to: To, options?: NavigateOptions) => navigate(to, options),
    setSelectedContact: (contact: SelectedContact) => {
      setSelectedContact(contact);
      setSelectedContactId(contact.id);
      setSelectedPortfolios(contact.portfolios.filter(portfolio => portfolio.status === "A") || [])
      navigate("/overview", {replace: true});
    },
  };

  //SetSelectedPortfolios must be moved to work, so it's set also on login.
  let showSelectList = false 

  if (contactData?.representees) {
    if (contactData?.representees.length > 15) {
      showSelectList = true
    }
  }


  return (
    <>
      <Menu as="div" className="grid relative items-center">
        <Menu.Button>
          <div className="w-8 h-8 rounded cursor-pointer" id="headlessuiMenyClicker">
            <MenuIcon className="h-full text-gray-900" />
          </div>
        </Menu.Button>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          as={Fragment}
        >
          <Menu.Items className="absolute top-full right-0 z-10 py-1 bg-white rounded-md ring-1 ring-black ring-opacity-5 shadow-lg origin-top-right focus:outline-none min-w-[120px]">
            {!showSelectList && getMenuItems(
              menuActions,
              !!linkedContact,
              canDeposit,
              canWithdraw,
              processes,
              contactData?.representees || [],
              { id: contactData?.contactId || "", contactId: contactData?.contactId || "", userName: contactData?.name || "-", initials: initials(contactData?.name), portfolios: contactData?.portfolios?.filter(portfolio => portfolio.status === "A") || [] },
              selectedContactId,
            ).map((item, index) => (
              (typeof item === "string") ? <Separator key={index}/> :
              (<MenuItem key={index} {...item} />)
            ))}
            {showSelectList && getMenuItemsSelect(
              menuActions,
              !!linkedContact,
              canDeposit,
              canWithdraw,
              processes,
              contactData?.representees || [],
              { id: contactData?.contactId || "", contactId: contactData?.contactId || "", userName: contactData?.name || "-", initials: initials(contactData?.name), portfolios: contactData?.portfolios?.filter(portfolio => portfolio.status === "A") || [] },
              selectedContactId,
            ).map((item, index) => (
              (typeof item === "string") ? <Separator key={index}/> :
              (<MenuItem key={index} {...item} />)
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
      <Modal {...depositModalProps} header={t("moneyModal.depositModalHeader")}>
        <DepositModalContent {...depositModalContentProps} />
      </Modal>
      <Modal
        {...withdrawModalProps}
        header={t("moneyModal.withdrawModalHeader")}
      >
        <WithdrawModalContent {...withdrawModalContentProps} />
      </Modal>
    </>
  );
};




interface MenuItemProps {
  label?: ReactNode;
  action?: () => void;
  Icon: ReactElementType;
  selected?: boolean;
}

const Separator = () => {
  return (
    <Menu.Item><hr /></Menu.Item>
  );
};

const MenuItem = ({ action, label, Icon, selected = false }: MenuItemProps) => {
  

  if (action) {
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          className={classNames(
            `p-2 pr-4 flex gap-2 items-center w-full text-base font-medium text-gray-900`,
            {
              "bg-primary-50": active,
            }
          )}
          onClick={action}
        >
          <Icon className="w-6 h-6" aria-hidden />
          <div className="items-center pr-2 w-full text-left whitespace-nowrap grow">
            <span>{label}</span>
          </div>
          <span className="">{selected && <CheckIcon />}</span>
        </button>
      )}
    </Menu.Item>
  )
          } else {
            return (
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={classNames(
                      `p-2 pr-4 flex gap-2 items-center w-full text-base font-medium text-gray-900`,
                      {
                        "bg-primary-50": active,
                      }
                    )}
                    onClick={e => {
                      e.preventDefault() 
                    }
                    }
                  >
                    <Icon className="w-6 h-6" aria-hidden />
                    <div className="items-center pr-2 w-full text-left whitespace-nowrap grow">
                      <span>{label}</span>
                    </div>
                    <span className="">{selected && <CheckIcon />}</span>
                  </button>
                )}
              </Menu.Item>
            )



          }


};
