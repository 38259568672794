import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { useMatchesBreakpoint } from "../../hooks/useMatchesBreakpoint";

export const Logo = () => {
  const { portfolioId } = useParams();
  const navigate = useNavigate();
  const isMdVersion = useMatchesBreakpoint("md");


  return (
    <div>
    {!isMdVersion && (
    <div
    className="w-10 h-10 rounded cursor-pointer"
    onClick={() =>
      navigate(
        portfolioId ? `/portfolio/${portfolioId}/overview` : "/overview"
      )
    }
  >
    <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="logo" />
  </div>
    )    }

{isMdVersion && (
    <div
    className="w-36 rounded cursor-pointer"
      onClick={() =>
        navigate(
          portfolioId ? `/portfolio/${portfolioId}/overview` : "/overview"
        )
      }
    >
      <img  src={`${process.env.PUBLIC_URL}/fullLogo.jpg`} alt="logo" />
    </div>
    )    }
    </div>
   
  );
};
