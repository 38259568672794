import { ReactNode } from "react";
import classNames from "classnames";

interface GainLoseColoringProps {
  children: ReactNode;
  value: number;
}
//
export const GainLoseColoring = ({
  children,
  value,
}: GainLoseColoringProps) => (
  <div 
    className={classNames({
      "text-lossTextColor": value < 0,
      "text-gainTextColor": value > 0,
    })}
  >
    {children}
  </div>
);
