import { gql, useQuery } from "@apollo/client";
import { getFetchPolicyOptions } from "api/utils";
import { useGlobalReportDate } from "hooks/useGlobalReportDate";
import { toShortISOString } from "utils/date";
import { PortfolioHoldingDetailsQuery } from "./types";

const HOLDING_DETAILS_QUERY = gql`
  query GetPortfolioHoldingDetails($portfolioId: Long, $reportDate: String) {
    portfolio(id: $portfolioId) {
      id
      portfolioReport(reportDate: $reportDate) {
        portfolioId
        holdingPositions: portfolioReportItems {
          portfolioId
          security {
            id
          }
          amount
          accruedInterest
          purchaseTradeAmount
          marketValue
          valueChangeAbsolute
          valueChangeRelative
          marketFxRate
          portfolioReportSubItems(
            filterParams: {
              openOnSnapshotDate: true
              snapshotDate: $reportDate
            }
          ) {
            transactionId
            purchaseDate
            amount
            purchaseUnitPrice
            purchaseCost
            purchaseTradeAmount
            purchaseFxRate
          }
        }
      }
    }
  }
`;
//
export const useGetPortfolioHoldingDetails = (
  portfolioId: string | undefined,
  securityId: string | undefined,
  repDate?: Date
) => {
  const globalReportDate = useGlobalReportDate();
  const { reportDate } = globalReportDate;
  const { loading, error, data } = useQuery<PortfolioHoldingDetailsQuery>(
    HOLDING_DETAILS_QUERY,
    {
      variables: {
        portfolioId,
        reportDate: toShortISOString(repDate || reportDate),
      },
      fetchPolicy: "cache-and-network",
    }
  );

  return {
    loading,
    error,
    data: data?.portfolio.portfolioReport.holdingPositions?.find(
      (holding) => holding.security.id.toString() === securityId
    ),
    ...globalReportDate,
  };
};
