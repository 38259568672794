import { HTMLAttributes, ReactNode } from "react";
import classNames from "classnames";

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  header?: ReactNode;
  onClick?: () => void;
}

export const Card = ({ children, header, onClick, ...props }: CardProps) => (
  <div
    className={classNames(
      "flex overflow-hidden flex-col w-full h-full bg-white rounded-lg border shadow-md",
      {
        "cursor-pointer": !!onClick,
      },
      props.className
    )}
    onClick={onClick}
  >
    {header && (
      <div className="p-2 md:px-4 text-xl font-bold bg-gray-200">{header}</div>
    )}
    {children}
  </div>
);
