import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Portfolio, useGetContactInfo } from "api/initial/useGetContactInfo";
import { NavigateOptions, To, useNavigate } from "react-router-dom";
import { initials } from "utils/initials";
import { useKeycloak } from "./KeycloakProvider";

export type SelectedContact = {
  id: string | number;
  contactId: string | number;
  userName: string;
  initials: string;
  portfolios: Array<Portfolio>;
};

export type SystemInfo = {
  id: string | number;
  name: string;
  header: string;
  alert: string;
  type: string;
};

type ContextProps = {
  selectedContactId: string | number;
  selectedContact: SelectedContact;
  selectedPortfolios: SelectedContact["portfolios"];
  currentAlert: SystemInfo;
  setSelectedContactId: Dispatch<SetStateAction<string | number>>;
  setSelectedContact: Dispatch<SetStateAction<SelectedContact>>;
  setSelectedPortfolios: Dispatch<
    SetStateAction<SelectedContact["portfolios"]>
  >;
  setCurrentAlert: Dispatch<SetStateAction<SystemInfo>>;
};

const ContractIdContext = createContext<ContextProps | undefined>(undefined);

export const DetailProvider = ({ children }: { children: ReactNode }) => {
  const { data: contactData } = useGetContactInfo();
  const [selectedContactId, setSelectedContactId] = useState<string | number>(
    ""
  );
  const [selectedContact, setSelectedContact] = useState<SelectedContact>(
    {} as SelectedContact
  );
  const [selectedPortfolios, setSelectedPortfolios] = useState<
    Array<Portfolio>
  >([]);
  const [currentAlert, setCurrentAlert] = useState<SystemInfo>(
    {} as SystemInfo
  );
  const { linkedContact, userProfile } = useKeycloak();

  useEffect(() => {
    linkedContact && setSelectedContactId(linkedContact);
    linkedContact &&
      setCurrentAlert({
        id: contactData?.systemAlert?.id ?? "",
        name: contactData?.systemAlert?.name ?? "",
        header: contactData?.systemAlert?.header ?? "",
        alert: contactData?.systemAlert?.alert ?? "",
        type: contactData?.systemAlert?.type ?? "",
      });
    linkedContact &&
      userProfile &&
      setSelectedContact({
        id: linkedContact,
        contactId: contactData?._contactId ?? "",
        userName: contactData?.name ?? "",
        initials: initials(contactData?.name),
        portfolios: [],
      });
  }, [
    linkedContact,
    userProfile,
    contactData?._contactId,
    contactData?.name,
    contactData?.systemAlert,
  ]);

  return (
    <ContractIdContext.Provider
      value={{
        selectedContactId,
        selectedContact,
        selectedPortfolios,
        currentAlert,
        setSelectedContactId,
        setSelectedContact,
        setSelectedPortfolios,
        setCurrentAlert,
      }}
    >
      {children}
    </ContractIdContext.Provider>
  );
};

export const useGetContractIdData = () => {
  const state = useContext(ContractIdContext);
  if (!state) throw new Error("detail data not found");

  return state;
};
