import { gql, QueryHookOptions, useQuery } from "@apollo/client";
import { useGlobalDateRange } from "hooks/useGlobalDateRange";
import { useGetContractIdData } from "providers/ContractIdProvider";
import { useKeycloak } from "providers/KeycloakProvider";
import { convertCompilerOptionsFromJson } from "typescript";
import { toShortISOString } from "utils/date";
import { ORDER_STATUS } from "./enums";
import { TRADE_ORDERS_DETAILS } from "./fragments";
import { AllTradeOrdersQuery, TradeOrder } from "./types";

const TRADE_ORDERS_QUERY = gql`
  ${TRADE_ORDERS_DETAILS}
  query GetAllPortfoliosTradeOrders(
    $contactId: Long
    $startDate: String
    $endDate: String
  ) {
    contact(id: $contactId) {
      id
      tradeOrders(
        transactionStartDate: $startDate
        transactionEndDate: $endDate
      ) {
        ...TradeOrdersDetails
      }
      transactions(
        startDate: $startDate
        endDate: $endDate
        status: "NF"
      ) {
        ...TradeOrdersDetails
      }
    }
  }
`;

export const useGetAllTradeOrders = (options?: QueryHookOptions) => {
  const dateRangeProps = useGlobalDateRange();
  const { startDate, endDate } = dateRangeProps;
  const { linkedContact } = useKeycloak();
  const { selectedContactId } = useGetContractIdData();
  const { loading, error, data } = useQuery<AllTradeOrdersQuery>(
    TRADE_ORDERS_QUERY,
    {
      variables: {
        startDate: toShortISOString(startDate),
        endDate: toShortISOString(endDate),
        contactId: selectedContactId || linkedContact,
      },
      fetchPolicy: "cache-and-network",
      ...options,
    }
  );


  
  const tradeOrderModified: TradeOrder[] = []


  data?.contact.transactions.forEach((transaction) => {
    const copyTransaction = {...transaction};
    if (copyTransaction.tags) {
      if (copyTransaction.tags.includes("Deposit-waiting") && copyTransaction.tags.includes("Group-Nets") && !copyTransaction.tags.includes("Group-NetsAG")) {
        copyTransaction.orderStatus = "-5" 
      } else if (copyTransaction.tags.includes("Deposit-pending") && copyTransaction.tags.includes("Group-Nets") && !copyTransaction.tags.includes("Group-NetsAG")) {
        copyTransaction.orderStatus = "-6" 
      } else {
        copyTransaction.orderStatus = "-9" 
      }
      
    }
    
    tradeOrderModified.push(copyTransaction)

  })

  data?.contact.tradeOrders.forEach((transaction) => {

    tradeOrderModified.push(transaction)
    
  })



  return {
    loading,
    error,
    data: tradeOrderModified,
    ...dateRangeProps,
  };
};
