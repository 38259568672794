import { useEffect, useState } from "react";
import { Form } from "@formio/react";
import classNames from "classnames";
import { Button, LoadingIndicator } from "components";
import "./styles.css";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { useLocation } from "react-router-dom";
import { keycloakService } from "services/keycloakService";
import { ApiError } from "./components/ApiError";
import { FormNotFound } from "./components/FormNotFound";
import { ProcessNotFound } from "./components/ProcessNotFound";
import { useProcessExecutor } from "./useProcessExecutor";

interface FormViewProps {
  header?: string;
  initialData?: Record<string, unknown>;
  formKey: string;
}

interface LocationProps {
  state: FormViewProps & {
    from: Location;
  };
}

export const FormModal = ({
  header,
  initialData: propsInitialData = {},
  formKey,
}: FormViewProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { t } = useModifiedTranslation();
  useEffect(() => {
    setIsLoaded(true);
  }, []);
  return (
    <>
      <div
        className={classNames(
          "fixed bg-gray-300 opacity-0 top-0 left-0 w-full h-full z-[1000] transition-opacity",
          { "opacity-50": isLoaded }
        )}
      ></div>
      <div className="fixed w-full h-full top-0 left-0 flex justify-center items-center z-[1001]">
        <div
          className={classNames(
            "border w-full min-w-[80%] sm:w-fit sm:max-h-[80%] -translate-y-32 sm:max-w-[90%] lg:min-w-[70%] xl:min-w-[50%] h-full sm:h-fit shadow-lg opacity-0 bg-white p-4 rounded-lg transition-all duration-[1s]",
            { "opacity-100 translate-y-0": isLoaded }
          )}
        >
          <div className="w-full flex justify-end items-center">
            <div className="formio-component-button">
              <button
                className="btn btn-primary btn-md"
                onClick={() => keycloakService.onAuthLogout()}
              >
                {t("userMenu.logout")}
              </button>
            </div>
          </div>
          <FormView
            header={header}
            initialData={propsInitialData}
            formKey={formKey}
          />
        </div>
      </div>
    </>
  );
};

const FormView = ({
  header,
  initialData: propsInitialData = {},
  formKey,
}: FormViewProps) => {
  const processState = useProcessExecutor(formKey);

  const { state: locationState } = useLocation() as unknown as LocationProps;

  if (processState.formDefinition) {
    processState.formDefinition.startData = {
      ...locationState?.initialData,
      ...propsInitialData,
      ...processState.initialData,
    };
  }

  return (
    <>
      {processState.executorState === "READY" &&
        processState.formDefinition && (
          <div className="formio-form">
            <Form
              key={processState.taskId}
              form={processState.formDefinition}
              onSubmit={processState.submitData}
              submission={{
                data: {
                  ...locationState?.initialData,
                  ...propsInitialData,
                  ...processState.initialData,
                },
              }}
            />
          </div>
        )}
      {processState.executorState === "READY" &&
        !processState.formDefinition && <FormNotFound />}
      {processState.executorState === "LOADING" && (
        <div className="h-screen">
          <LoadingIndicator center />
        </div>
      )}
      {processState.executorState === "SUBMITTING" && (
        <div className="h-screen">
          <LoadingIndicator center />
        </div>
      )}
      {processState.executorState === "SUBMIT_ERROR" && (
        <ApiError resetApiError={processState.resetApiError} />
      )}
      {processState.executorState === "PROCESS_ERROR" && <ProcessNotFound />}
    </>
  );
};
