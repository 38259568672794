import { gql, useQuery } from "@apollo/client";
import { alertSec_id } from "config";
import { fallbackLanguage } from "i18n";
import { useKeycloak } from "providers/KeycloakProvider";
import {
  DepositPermissionGroup,
  WithdrawalPermissionGroup,
} from "services/permissions/money";
import { TradePermissionGroup } from "services/permissions/trade";

const CONTACT_INFO_QUERY = gql`
  query GetContactInfo($contactId: Long, $secId: Long) {
    portalAlert: security(id: $secId) {
      id
      name
      header: url
      alert: description
      type: url2
    }
    contact(id: $contactId) {
      id
      contactId
      name
      language {
        locale
      }
      tagsAsSet
      representees(onlyDirectRepresentees: true) {
        name
        id
        contactId
        status
        portfolios {
          id
          name
          shortName
          status
          currency {
            securityCode
          }
          portfolioGroups {
            id
            code
          }
          type {
            code
            name
          }
        }
        representees {
          name
          contactId
        }
      }
      assetManagerPortfolios {
        primaryContact {
          contactId
          name
        }
      }
      portfolios {
        id
        name
        shortName
        status
        currency {
          securityCode
        }
        portfolioGroups {
          id
          code
        }
        type {
          code
          name
        }
      }
    }
  }
`;

interface PortfolioGroup {
  code:
    | typeof TradePermissionGroup
    | typeof DepositPermissionGroup
    | typeof WithdrawalPermissionGroup;
}

export interface Representee {
  id: number;
  name: string;
  contactId: string;
  status: string;
  portfolios: Portfolio[];
  representees: [];
}

interface AssetManagerPortfolios {
  primaryContact: {
    contactId: string;
    name: string;
  };
}

export interface Portfolio {
  id: number;
  name: string;
  shortName: string;
  status: string;
  currency: {
    securityCode: string;
  };
  portfolioGroups: PortfolioGroup[];
  type: {
    code: string;
    name: string;
  };
}

export interface ContactInfoQuery {
  portalAlert?: {
    id: number;
    name: string;
    header: string;
    alert: string;
    type: string;
  };
  contact?: {
    id: number;
    contactId: string;
    name: string;
    representees: Representee[];
    tagsAsSet: string[];
    assetManagerPortfolios: AssetManagerPortfolios[];
    language: {
      locale: string;
    };
    portfolios?: Portfolio[];
  };
}

export interface ContactsInfoQuery {
  contacts?: {
    name: string;
    representees: Representee[];
    assetManagerPortfolios: AssetManagerPortfolios[];
  }[];
}

export const useGetContactInfo = (
  callAPI = false,
  selectedContactId?: string | number,
  queryWithRepresentees?: boolean
) => {
  const { linkedContact } = useKeycloak();
  const { loading, error, data } = useQuery<ContactInfoQuery>(
    CONTACT_INFO_QUERY,
    {
      variables: {
        contactId: selectedContactId || linkedContact,
        secId: alertSec_id,
      },
      fetchPolicy: callAPI ? "cache-and-network" : "cache-first",
    }
  );

  //console.log( new Date() +"Running get contatinfo")
  return {
    loading: loading,
    error: error,
    data: data && {
      contactId: data.contact?.id,
      _contactId: data.contact?.contactId,
      //portfolios: data.contact?.portfolios || [],
      portfolios:
        data.contact?.portfolios
          ?.filter((portfolio) => portfolio.status === "A")
          .sort((portA, portB) =>
            portA.type.code.localeCompare(portB.type.code)
          ) || [],
      locale: data.contact?.language?.locale || fallbackLanguage,
      // all contact portfolios have same currency
      portfoliosCurrency:
        data.contact?.portfolios?.[0]?.currency.securityCode || "",
      representees:
        data?.contact?.representees
          ?.filter((rep) => rep.status === "A")
          .sort((repA, repB) => repA.name.localeCompare(repB.name)) || [],
      assetManagerPortfolios: data?.contact?.assetManagerPortfolios,
      name: data.contact?.name,
      systemAlert: data.portalAlert,
      tags: data.contact?.tagsAsSet,
    },
  };
};
